<template>
  <section id="home" class="header_area">
    <!-- Start Top Nav -->
    <nav class="navbar navbar-expand-lg bg-dark navbar-light d-none d-lg-block" id="nav_top">
      <div class="container text-light">
        <div class="w-100 d-flex justify-content-between">
          <div>
            <i class="fa fa-envelope ml-1 mr-2"></i>
            <a class="navbar-sm-brand text-light text-decoration-none" href="mailto:csa@enet.cu">csa@enet.cu</a>
            <i class="fa fa-phone mx-2"></i>
            <a class="navbar-sm-brand text-light text-decoration-none" href="#">teléfono: 7 204 2181</a>
          </div>
          <div>
            <a class="text-light" href="https://facebook.com/csautomotrices" target="_blank" rel="sponsored"><i class="lni lni-facebook-original fa-sm fa-fw me-2"></i></a>
            <a class="text-light ml-20 mr-1" href="https://instagram.com/csautomotrices" target="_blank"><i class="lni lni-instagram-original fa-sm fa-fw me-2"></i></a>
<!--            <a class="text-light" href="#" target="_blank"><i class="fab fa-twitter fa-sm fa-fw me-2"></i></a>-->
<!--            <a class="text-light" href="#" target="_blank"><i class="fab fa-linkedin fa-sm fa-fw"></i></a>-->
          </div>
        </div>
      </div>
    </nav>
    <!-- Close Top Nav -->
    <div id="header_navbar" class="header_navbar">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <nav class="navbar navbar-expand-lg">
              <a class="navbar-brand" href="#">
                <img id="logo" :src="logo" alt="Logo" />
              </a>
              <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
              >
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
              </button>
              <div
                  class="collapse navbar-collapse sub-menu-bar"
                  id="navbarSupportedContent"
              >
                <ul id="nav" class="navbar-nav ml-auto">
                  <li v-for="link in menu" :key="link.url" class="nav-item">
                    <a class="page-scroll" :href="link.url">{{ link.label }}</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="header_hero">
      <div
          class="single_hero bg_cover d-flex align-items-center"
      >
        <video playbackRate=0.6 autoplay muted loop>
          <source src="assets/videos/webheader.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
              <div class="hero_content text-center">
                <h2
                    class="hero_title wow fadeInUp"
                    data-wow-duration="1.3s"
                    data-wow-delay="0.2s"
                >
                  COOPERATIVA DE SERVICIOS AUTOMOTRICES
                </h2>
                <h5
                    class="wow fadeInUp"
                    data-wow-duration="1.3s"
                    data-wow-delay="0.5s"
                >
                  "Ocupate solo de conducir"
                </h5>
                <a
                  href="#features"
                  class="main-btn wow fadeInUp"
                  data-wow-duration="1.3s"
                  data-wow-delay="0.8s"
                >VER MÁS</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {ref} from "vue";

export default {
  name: 'home',

  setup () {
    const logo = ref('assets/images/logo.svg')
    const menu = ref([
      {label: 'Inicio', url: '#home'},
      {label: 'Sobre Nosotros', url: '#about'},
      {label: 'Marcas', url: '#brands'},
      {label: 'Servicios', url: '#services'},
      {label: 'Equipo', url: '#team'},
      // {label: 'Tienda', url: '/tienda'},
      {label: 'Contáctenos', url: '#contact'},
    ])

    return {
      logo,
      menu
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.single_hero {
  height: 750px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
@media only screen and (min-width: 1400px) {
  .single_hero {
    height: 950px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_hero {
    height: 700px;
  }
}
@media (max-width: 767px) {
  .single_hero {
    height: 650px;
  }
}
.single_hero::before {
  position: absolute;
  content: "";
  background-color: rgba(43, 42, 61, 0.7);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
.single_hero video {
  position: absolute;
  width: 100%;
  z-index: -100;
}
.hero_content {
  padding-top: 50px;
  z-index: 1000;
}
.hero_content .hero_title {
  font-size: 50px;
  /* line-height: 85px; */
  color: #fff;
  margin-top: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero_content .hero_title {
    font-size: 44px;
    line-height: 65px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero_content .hero_title {
    font-size: 40px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .hero_content .hero_title {
    font-size: 24px;
    line-height: 38px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero_content .hero_title {
    font-size: 40px;
    line-height: 60px;
  }
}
.hero_content .hero_title span {
  display: contents;
  color: #f19700;
}
.hero_content h5 {
  /* font-size: 16px; */
  color: #fff;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .hero_content p {
    font-size: 16px;
  }
}
.hero_content .main-btn {
  margin-top: 45px;
}
#nav_top {
  height: 30px;
  min-height: 30px;
  border-radius: 0;
  position: fixed;
  z-index: 1001;
  padding: 5px;
  width: 100%;
}
#nav_top * {
  font-size: 12px !important;
}
</style>
