<template>
  <section id="services" class="portfolio_area pt-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section_title text-center pb-30">
            <h4
                class="title wow fadeInUp"
                data-wow-duration="1.3s"
                data-wow-delay="0.2s"
            >
              Servicios
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="portfolio_wrapper d-flex flex-wrap">
      <div
          v-for="service in services" :key="service.index"
          class="single_portfolio wow fadeInUp"
          data-wow-duration="1.3s"
          :data-wow-delay="(service.index * 0.2)+'s'"
      >
        <img :src="service.url" alt="portfolio" />
        <div class="portfolio_content">
<!--          <ul class="meta">
            <li>
              <a href="#"><i class="lni lni-link"></i></a>
            </li>
          </ul>-->
          <h5 class="portfolio_title text-capitalize">{{service.name}}</h5>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'services',

  data () {
    return{
      services: [
        { index: 1, name: 'Diagnóstico linea VW y Renault', url: 'assets/images/services/Diagnostico_linea_VW_y_Renault.jpg' },
        { index: 2, name: 'Diagnóstico Vehículos Electricos', url: 'assets/images/services/diagnostico_para_veh_culos_electricos.jpg' },
        { index: 3, name: 'Mantenimiento General', url: 'assets/images/services/Mantenimiento_General.jpg' },
        { index: 4, name: 'Ponchera y Balanceo de Neumáticos', url: 'assets/images/services/ponchera_y_balanceo_de_naumaticos.jpg' },
        { index: 5, name: 'Reparación de  Aire Acondicionado', url: 'assets/images/services/reparaci_n_de__aire_acondicionado.jpg' },
        { index: 6, name: 'reparación de motores de arranque y alternadores', url: 'assets/images/services/reparaci_n_de_motores_de_arranque_y_alternadores.jpg' },
        { index: 7, name: 'reparación de motores de combustión interna', url: 'assets/images/services/reparaci_n_de_motores_de_combusti_n_interna.jpg' },
        { index: 8, name: 'reparacion de sistemas de dirección', url: 'assets/images/services/reparacion_de_sistemas_de_direcci_n.jpg' },
        { index: 9, name: 'reparacion de sistemas de freno', url: 'assets/images/services/reparacion_de_sistemas_de_freno.jpg' },
        { index: 10, name: 'reparación de sistemas de suspensión', url: 'assets/images/services/reparaci_n_de_sistemas_de_suspensi_n.jpg' },
        { index: 11, name: 'servicio de empapelado', url: 'assets/images/services/servicio_de_empapelado.jpg' },
        { index: 12, name: 'servicio de auxilio en la vía', url: 'assets/images/services/servicio_de_auxilio_en_la_v_a.jpg' },
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.single_portfolio {
  position: relative;
  width: 25%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_portfolio {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .single_portfolio {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single_portfolio {
    width: 50%;
  }
}
.single_portfolio img {
  width: 100%;
}
.single_portfolio::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 176, 0);
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.single_portfolio .portfolio_content {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}
.single_portfolio .portfolio_content .meta li {
  display: inline-block;
  margin: 0 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
}
.single_portfolio .portfolio_content .meta li a {
  width: 35px;
  height: 35px;
  line-height: 33px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.single_portfolio .portfolio_content .meta li a:hover {
  background-color: #fff;
  color: #f19700;
}
.single_portfolio .portfolio_content .meta li:first-child {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}
.single_portfolio .portfolio_content .meta li:last-child {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}
.single_portfolio .portfolio_content .portfolio_title {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin-top: 25px;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_portfolio .portfolio_content .portfolio_title {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .single_portfolio .portfolio_content .portfolio_title {
    font-size: 20px;
  }
}
.single_portfolio:hover::before {
  opacity: 1;
}
.single_portfolio:hover .portfolio_content .meta li {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -ms-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
  opacity: 1;
}
.single_portfolio:hover .portfolio_content .portfolio_title {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -ms-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
  opacity: 1;
}
</style>
