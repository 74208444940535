<template>
  <Preloader/>

  <Home />

  <Features />

  <About />

  <Brands />

  <Services />

  <Team />

  <Contact />

<!--  <section id="testimonial"
    class="testimonial_area pt-130 pb-130 bg_cover"
    style="background-image: url(assets/images/testimonial.jpg)"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="glide testimonial_active">
            <div class="glide__track" data-glide-el="track">
              <div class="glide__slides">
                <div class="glide__slide single_testimonial text-center">
                  <img src="assets/images/author-1.jpg" alt="author" />
                  <h5 class="author_name">Linda</h5>
                  <span>UX Specialist, Yoast</span>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren.
                  </p>
                </div>
                <div class="glide__slide single_testimonial text-center">
                  <img src="assets/images/author-2.jpg" alt="author" />
                  <h5 class="author_name">Nancy</h5>
                  <span>Sr. Software Engineer</span>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren.
                  </p>
                </div>
                <div class="glide__slide single_testimonial text-center">
                  <img src="assets/images/author-3.jpg" alt="author" />
                  <h5 class="author_name">Elon Musk</h5>
                  <span>Creaitve Businessman</span>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren.
                  </p>
                </div>
              </div>
            </div>
            <div class="glide__bullets" data-glide-el="controls[nav]">
              <button class="glide__bullet" data-glide-dir="=0"></button>
              <button class="glide__bullet" data-glide-dir="=1"></button>
              <button class="glide__bullet" data-glide-dir="=2"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="blog" class="blog_area pt-120 pb-130">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section_title text-center pb-25">
            <h4
              class="title wow fadeInUp"
              data-wow-duration="1.3s"
              data-wow-delay="0.2s"
            >
              Recent Blog
            </h4>
            <p
              class="wow fadeInUp"
              data-wow-duration="1.3s"
              data-wow-delay="0.4s"
            >
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt labor dolore.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div
            class="single_blog mt-30 wow fadeInUp"
            data-wow-duration="1.3s"
            data-wow-delay="0.2s"
          >
            <div class="blog_image">
              <img src="assets/images/blog-1.jpg" alt="blog" />
            </div>
            <div class="blog_content">
              <h3 class="blog_title">
                <a href="#"
                  >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor .</a
                >
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt labor dolore.Lorem ipsum
                dolor sit amet, conse sadipscing elitr, sed diam nonumy eirmod
                tempor invidunt labor dolore magna .Lorem ipsum dolor sit amet,
                consetetur sadipscing elit.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="single_blog blog_2 d-sm-flex mt-30 wow fadeInUp"
            data-wow-duration="1.3s"
            data-wow-delay="0.2s"
          >
            <div class="blog_image">
              <img src="assets/images/blog-2.jpg" alt="blog" />
            </div>
            <div class="blog_content media-body">
              <h3 class="blog_title">
                <a href="#"
                  >Lorem ipsum dolor sit amet, consetetur sadipscing .</a
                >
              </h3>
              <p>
                Lorem ipsum dolor sit consetetur sadipscing elitr, sed diam.
              </p>
              <a href="#" class="more">Read More</a>
            </div>
          </div>
          <div
            class="single_blog blog_2 d-sm-flex mt-30 wow fadeInUp"
            data-wow-duration="1.3s"
            data-wow-delay="0.3s"
          >
            <div class="blog_image">
              <img src="assets/images/blog-3.jpg" alt="blog" />
            </div>
            <div class="blog_content media-body">
              <h3 class="blog_title">
                <a href="#"
                  >Lorem ipsum dolor sit amet, consetetur sadipscing .</a
                >
              </h3>
              <p>
                Lorem ipsum dolor sit consetetur sadipscing elitr, sed diam.
              </p>
              <a href="#" class="more">Read More</a>
            </div>
          </div>
          <div
            class="single_blog blog_2 d-sm-flex mt-30 wow fadeInUp"
            data-wow-duration="1.3s"
            data-wow-delay="0.4s"
          >
            <div class="blog_image">
              <img src="assets/images/blog-4.jpg" alt="blog" />
            </div>
            <div class="blog_content media-body">
              <h3 class="blog_title">
                <a href="#"
                  >Lorem ipsum dolor sit amet, consetetur sadipscing .</a
                >
              </h3>
              <p>
                Lorem ipsum dolor sit consetetur sadipscing elitr, sed diam.
              </p>
              <a href="#" class="more">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>-->

  <footer id="footer" class="footer_area">
    <div class="container">
      <div
        class="footer_wrapper text-center d-lg-flex align-items-center justify-content-between"
      >
        <p class="credit">
          ©Cooperativa de Servicios Automotrices
        </p>
        <div class="footer_social pt-15">
          <ul>
            <li>
              <a href="https://facebook.com/csautomotrices"><i class="lni lni-facebook-original"></i></a>
            </li>
            <li>
<!--              <a href="#"><i class="lni lni-twitter-original"></i></a>-->
            </li>
            <li>
              <a href="https://instagram.com/csautomotrices"><i class="lni lni-instagram-original"></i></a>
            </li>
            <li>
<!--              <a href="#"><i class="lni lni-linkedin-original"></i></a>-->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>

  <a href="#" class="back-to-top"><i class="lni lni-chevron-up"></i></a>
</template>

<script>
// import HelloWorld from './components/Preloader.vue'
import Preloader from "@/components/Preloader";
import Home from "@/components/Home";
import Features from "@/components/Features";
import About from "@/components/About";
import Brands from "@/components/Brands";
import Services from "@/components/Services";
import Team from "@/components/Team";
import Contact from "@/components/Contact";

export default {
  name: 'App',

  components: {
    Contact,
    Services,
    Brands,
    About,
    Team,
    Home,
    Features,
    Preloader
  },

  data() {
      return {
      }
  }
};
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
