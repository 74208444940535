<template>
  <section id="about" class="pt-95">
    <div class="about_area">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="about_content pt-80 pb-130">
              <div class="section_title pb">
                <h4
                    class="title wow fadeInUp"
                    data-wow-duration="1.3s"
                    data-wow-delay="0.2s"
                >
                  Quiénes somos
                </h4>
                <p
                    class="wow fadeInUp"
                    data-wow-duration="1.3s"
                    data-wow-delay="0.4s"
                >
                  Somos un grupo de emprendedores que trabajamos juntos de manera eficiente, abiertos a las nuevas tecnologías y con ideas novedosas que buscamos permanentemente dar respuesta a las necesidades esenciales de su auto. Contamos con un personal capacitado constantemente en las herramientas y nuevas tecnologías aplicadas a la mecánica automotriz.
                  <br>Trabajamos en conjunto para alcanzar la calidad y excelencia en los servicios que prestamos. Nuestro esfuerzo está orientado a mantener su vehículo en las mejores condiciones y de esa manera conseguir la entera satisfacción de nuestros clientes.
                </p>
              </div>
<!--              <a
                  href="#"
                  class="main-btn wow fadeInUp"
                  data-wow-duration="1.3s"
                  data-wow-delay="0.7s"
              >Leer más</a
              >-->
            </div>
          </div>
        </div>
      </div>
      <div
          class="about_image bg_cover wow fadeInLeft"
          data-wow-duration="1.3s"
          data-wow-delay="0.2s"
          style="background-image: url('assets/images/aboutcsa.jpg')"
      >
        <div class="image_content">
          <h4 class="experience"><span>{{ years }}</span> Años<br> de Servicios</h4>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'about',

  data () {
    return{
      years: 6,
    }
  },

  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.about_area {
  background-color: #f9f9f9;
  position: relative;
}
.about_image {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about_image {
    width: 100%;
    height: 500px;
    position: relative;
  }
}
@media (max-width: 767px) {
  .about_image {
    width: 100%;
    height: 400px;
    position: relative;
  }
}
.about_image .image_content {
  width: 140px;
  height: 120px;
  padding: 15px;
  background-color: #f19700;
  display: table;
  text-align: center;
  position: relative;
  left: -68px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about_image .image_content {
    width: 135px;
    height: 135px;
    left: -78px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about_image .image_content {
    left: 0;
  }
}
@media (max-width: 767px) {
  .about_image .image_content {
    left: 0;
    width: 135px;
    height: 135px;
  }
}
.about_image .image_content .experience {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  display: table-cell;
  vertical-align: middle;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about_image .image_content .experience {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .about_image .image_content .experience {
    font-size: 18px;
  }
}
.about_image .image_content .experience span {
  font-size: 34px;
  font-weight: 700;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about_image .image_content .experience span {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  .about_image .image_content .experience span {
    font-size: 36px;
  }
}
.about_content {
  padding-right: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about_content {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .about_content {
    padding-right: 0;
  }
}
.about_content .main-btn {
  margin-top: 30px;
}
</style>
