<template>
  <section id="features" class="features_area pt-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section_title text-center pb-25">
            <h4
                class="title wow fadeInUp"
                data-wow-duration="1.3s"
                data-wow-delay="0.2s"
            >
              ¿Por qué debes escogernos?
            </h4>
            <p
                class="wow fadeInUp"
                data-wow-duration="1.3s"
                data-wow-delay="0.4s"
            >
              Nuestra cooperativa realiza sus servicios enfocándose en usted, garantizando la mejor calidad, el menor tiempo y el menor costo posibles para todos nuestros clientes.
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div v-for="element in features" :key="element.title" class="col-lg-4 col-md-7">
          <div
              class="single_features text-center mt-30 wow fadeInUp"
              data-wow-duration="1.3s"
              data-wow-delay="0.2s"
          >
            <mdicon :name="element.icon" size="60" />
            <h4 class="features_title">{{ element.title }}</h4>
            <p>
              {{ element.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'home',

  data () {
    return{
      features: [
        {
          title: 'Profesionalidad',
          description: 'Mantenemos un comportamiento profesional todo el tiempo, practicando la cortesía y poniendo nuestros conocimientos a su disposición',
          icon: 'account-tie-outline'
        },
        {
          title: 'Calidad de los Servicios',
          description: 'La calidad nos identifica, ponemos todo nuestro empeño en satisfacer las necesidades y expectativas de nuestros clientes',
          icon: 'certificate'
        },
        {
          title: 'Atención al Cliente',
          description: 'Nos enfocamos en el cliente, brindándole el respeto merecido y resolviendo todas sus dudas',
          icon: 'target-account'
        }
      ],
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.single_features svg {
  height: 60px;
  width: 60px;
  color: #f19700;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 767px) {
  .single_features svg {
    height: 40px;
    width: 40px;
    line-height: 45px;
  }
}
.single_features i {
   font-size: 60px;
   line-height: 60px;
   color: #f19700;
   -webkit-transition: all 0.3s ease-out 0s;
   -moz-transition: all 0.3s ease-out 0s;
   -ms-transition: all 0.3s ease-out 0s;
   -o-transition: all 0.3s ease-out 0s;
   transition: all 0.3s ease-out 0s;
 }
@media (max-width: 767px) {
  .single_features i {
    font-size: 40px;
    line-height: 45px;
  }
}
.single_features .features_title {
  color: #f19700;
  margin-top: 15px;
  font-size: 24px;
  font-weight: 600;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 767px) {
  .single_features .features_title {
    font-size: 24px;
  }
}
.single_features p {
  margin-top: 15px;
}
.single_features:hover i {
  color: #f19700;
}
</style>
