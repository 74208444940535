<template>
  <section id="team" class="team_area pt-120 pb-130">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section_title text-center pb-25">
            <h4
                class="title wow fadeInUp"
                data-wow-duration="1.3s"
                data-wow-delay="0.2s"
            >
              Nuestro Equipo
            </h4>
            <p
                class="wow fadeInUp"
                data-wow-duration="1.3s"
                data-wow-delay="0.4s"
            >
              Somos un equipo muy unido, trabajando por un mismo objetivo, nuestros clientes.<br>
              Aquí algunos de nuestros directores
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center team_active">
        <div v-for="employee in employees" :key="employee.index" class="col-lg-3 col-md-6 col-sm-10">
          <div
              class="single_team mt-20 wow fadeInUp"
              data-wow-duration="1.3s"
              :data-wow-delay="(employee.index * 0.2)+'s'"
          >
            <img :src="employee.photo" alt="team" />
            <div class="team_content">
              <h6 class="team_name">{{ employee.name }}</h6>
              <p>{{ employee.occupation }}</p>
              <ul class="social">
<!--                <li>
                  <a :href="employee.facebook"><i class="lni lni-facebook-filled"></i></a>
                </li>-->
<!--                <li>
                  <a href="#"><i class="lni lni-twitter-original"></i></a>
                </li>
                <li>
                  <a href="#"><i class="lni lni-linkedin-original"></i></a>
                </li>-->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'team',

  data () {
    return{
      employees: [
        {
          index: 1,
          name: 'Anyel',// Calles Roldós
          photo: 'assets/images/team/4-2.jpg',
          occupation: 'Jefa Comercial',
          facebook: 'https://www.facebook.com/anyel.calles'
        },
        {
          index: 2,
          name: 'Jorge',// Albarello Fernández
          photo: 'assets/images/team/14.jpg',
          occupation: 'Jefe de Taller',
          facebook: 'https://www.facebook.com/jorge.albarellofernandez.1'
        },
        {
          index: 3,
          name: 'Maria Elena ',
          photo: 'assets/images/team/50.jpg',
          occupation: 'Jefa Económica',
          facebook: 'https://www.facebook.com/mariaelena.suarezbergado'
        },
        {
          index: 4,
          name: 'Alexier',// Caballero Vivar
          photo: 'assets/images/team/8.jpg',
          occupation: 'Jefe de Logística',
          facebook: 'https://www.facebook.com/alexier.caballero'
        },
      ]
    }
  },

  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>.single_team {
  position: relative;
}
.single_team img {
  width: 100%;
}
.single_team .team_content {
  position: absolute;
  background-color: rgba(0,0,0,0.7);
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 8px;
  /*padding-right: 60px;*/
}
.single_team .team_content .team_name {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.single_team .team_content .team_name:hover {
  color: #f19700;
}
.single_team .team_content p {
  color: #fff3cd;
  margin-top: 5px;
}
.single_team .team_content .social {
  position: absolute;
  bottom: -10px;
  right: 5px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.single_team .team_content .social li {
  margin: 3px 0;
}
.single_team .team_content .social li a {
  color: #fff;
  font-size: 16px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.single_team .team_content .social li a:hover {
  color: #f19700;
}
</style>
