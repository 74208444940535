<template>
  <section id="contact"
           class="contact_area bg_cover pt-120 pb-130"
           style="background-image: url('assets/images/contact_bg.jpg')"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section_title section_title_2 text-center pb-25">
            <h4
                class="title wow fadeInUp"
                data-wow-duration="1.3s"
                data-wow-delay="0.2s"
            >
              Contáctenos
            </h4>
            <p
                class="wow fadeInUp"
                data-wow-duration="1.3s"
                data-wow-delay="0.4s"
            >
              Nuestro equipo le responderá lo antes posible, no dude en contactarnos.
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-between">
        <div class="col-md-6">
          <form
              id="contact-form"
              @submit.prevent="sendMail"
              method="post"
              class="wow fadeInUp"
              data-wow-duration="1.3s"
              data-wow-delay="0.4s"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="single_form">
                  <input
                      v-model="name"
                      type="text"
                      placeholder="Nombre"
                      name="name"
                      id="name"
                      required
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="single_form">
                  <input
                      v-model="email"
                      type="email"
                      placeholder="Correo"
                      name="email"
                      id="email"
                      required
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="single_form">
                  <input
                      v-model="phone"
                      type="text"
                      placeholder="Número de Teléfono"
                      name="number"
                      id="number"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="single_form">
                  <input
                      v-model="subject"
                      type="text"
                      placeholder="Asunto"
                      name="subject"
                      id="subject"
                      required
                  />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="single_form">
              <textarea
                  v-model="message"
                  placeholder="Mensaje"
                  name="message"
                  id="message"
                  required
              ></textarea>
                </div>
              </div>
              <p class="form-message"></p>
              <div class="col-lg-12">
                <div class="single_form text-center">
                  <button class="main-btn" type="submit" :disabled="loading">{{ (loading)? 'Enviando...' : 'ENVIAR' }}</button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-6 text-center">
          <h4 class="text-light mt-25">
            Encuéntranos en:
          </h4>
          <p class="address_contact text-grey mt-2">
            <mdicon name="map-marker"/> Calle 1era entre 6 y 8, Playa, La Habana, Cuba.
          </p>
          <p class="phones_contact text-grey mt-2">
            <mdicon name="email"/> Correo: <a class="mailito" :href="'mailto:'+mail">{{ mail }}</a>
          </p>
          <p class="phones_contact text-grey mt-2">
            <mdicon name="phone"/> Teléfonos:   (+53) 7 204 2181, (+53) 7 204 4118
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'contact',

  data () {
    return{
      loading: false,
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
      mail: 'csa@enet.cu',
    }
  },

  methods: {
    sendMail () {
      this.loading = true
      this.axios.post('/assets/contact.php',JSON.stringify({
        name: this.name,
        email: this.email,
        phone: this.phone,
        subject: this.subject,
        message: this.message
      }))
        .then(response => {
          console.log(response)
          this.$toast.success('¡Mensaje enviado correctamente!, Gracias por contactarnos.')
          this.loading = false
          this.name = ''
          this.email = ''
          this.phone = ''
          this.subject = ''
          this.message = ''
        })
        .catch(error => {
          console.log(error)
          this.$toast.error(error.message)
          this.loading = false
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contact_area {
  position: relative;
  z-index: 5;
}
.contact_area::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(43, 42, 61, 0.9);
  z-index: -1;
}
p.form-message.success,
p.form-message.error {
  font-size: 16px;
  color: #2b2a3d;
  background: #f9f9f9;
  padding: 10px 15px;
  margin-top: 30px;
  background-color: #fff;
  margin-left: 15px;
}
p.form-message.success.form-message.error,
p.form-message.error.form-message.error {
  color: red;
}
.single_form {
  margin-top: 30px;
}
.single_form input,
.single_form textarea {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  padding: 0 25px;
  font-size: 16px;
  color: #fff;
  background: 0 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.single_form input:focus,
.single_form textarea:focus {
  border-color: #fff;
}
.single_form textarea {
  height: 120px;
  resize: none;
  padding-top: 15px;
}
.mailito {
  color: #f19700;
}
.text-grey{
  color: #9b9ba7;
}
</style>
