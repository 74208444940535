<template>
  <section id="brands" class="features_area pt-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section_title text-center pb-25">
            <h4
                class="title wow fadeInUp"
                data-wow-duration="1.3s"
                data-wow-delay="0.2s"
            >
              Marcas que trabajamos
            </h4>
            <p
                class="wow fadeInUp"
                data-wow-duration="1.3s"
                data-wow-delay="0.4s"
            >
              Grupo completo de Volkswagen(VW, Audi, Seat y Skoda)<br>
              RENAULT en los modelos (Duster, Sandero, Scala, Kadjar, Kangoo, Fluence, Latitude,Talismán)<br>
              Kia (Todos sus modelos)<br>
              Hyundai (Todos sus modelos)<br>
              Toyota (Todos sus modelos)
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-for="logo in logos" :key="logo.index" class="col-lg-3 col-sm-6">
          <div
              class="text-center mt-30 wow fadeInUp"
              data-wow-duration="1.3s"
              :data-wow-delay="(logo.index * 0.2)+'s'"
          >
            <img class="brands-logos" :src="logo.url" :alt="logo.name+' logo'" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'brands',

  data () {
    return{
      logos:[
        { index: 1, name: 'vw', url: 'assets/images/brands/vwcolor.png'},
        { index: 2, name: 'audi', url: 'assets/images/brands/AudiColor.png'},
        { index: 3, name: 'seat', url: 'assets/images/brands/seat-color.png'},
        { index: 4, name: 'skoda', url: 'assets/images/brands/skodaColor.png'},
        { index: 5, name: 'renault', url: 'assets/images/brands/renaultcolor.png'},
        { index: 6, name: 'kia', url: 'assets/images/brands/kiaColor.png'},
        { index: 7, name: 'hyundai', url: 'assets/images/brands/hyundai.png'},
        { index: 8, name: 'toyota', url: 'assets/images/brands/Toyota.png'}
      ],
    }
  },

  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.brands-logos {
  height: 100px;
  min-height: 100px;
}
</style>
